<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'oc-ha',
  primary_color: '#007098',
  secondary_color: '#96FFE0',
  header: {
    background: {
      image: `/projekt/oc-ha/elbworx-projekt-oc-ha-header-2362.webp`,
      black_tint_off: true,
    },
    project_class: 'text-elbworx-black',
    project: 'OC-HA Lohse & Team',
    headline: 'Mit Präzision<br> am Werk',
    info_card: {
      description:
        'Modernste, innovative Diagnostik und Behandlung: Das Lächeln seiner Patienten ist Christian Lohses Ziel!',
      client: 'Oralchirurgische Fachzahnarztpraxis Höchstadt a/d Aisch',
      roles: ['Corporate Design'],
      project_duration: '2021 bis 2022',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-primary">Die Herausforderung</h3>

      <p>
        Seine oralchirurgische Fachzahnarztpraxis in Höchstadt an der Aisch übernahm Christian Lohse
        durch Altersnachfolge. Wird eine bestehende Einrichtung übernommen, wünschen sich viele
        Nachfolger ein neues Erscheinungsbild für ihre Praxis — so auch im Falle von OC-HA.
      </p>
      <p>
        Das Besondere an Christian Lohses Situation: Seine Praxis kann sich nicht auf
        „Laufkundschaft“ verlassen. Als Oralchirurg ist er auf Überweisungen aus Zahnarztpraxen
        angewiesen, die im Falle einer Zusammenarbeit schwere Fälle an ihn verweisen. Das
        Praxis-Marketing richtet sich also nicht nur an Patienten, sondern in besonderer Weise auch
        an zahnmedizinische Kollegen, die es von der eigenen Erfahrung, Professionalität und
        Vertrauenswürdigkeit zu überzeugen gilt. Dem Corporate Design der Praxis und dessen
        Herzstück — dem Logo — kommt dabei eine zentrale Rolle zu.
      </p>
    </template>

    <Padding class="flex flex-col items-center">
      <div class="max-w-xl">
        <Image :src="`${projectPath}ocha-logo.svg`" />
      </div>
    </Padding>
    <Grid class="mt-28 items-end">
      <div class="col-span-5">
        <Image
          class="w-full object-cover pt-24"
          :src="`${projectPath}elbworx-projekt-oc-ha-brochure-1920.webp`"
        />
      </div>
      <div class="col-span-7">
        <Padding only="right">
          <Image
            class="w-full"
            :src="`${projectPath}elbworx-projekt-oc-ha-stand_banner-1920.webp`"
          />
        </Padding>
      </div>
    </Grid>
    <Grid class="items-end">
      <Padding only="left" class="col-span-11 col-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-oc-ha-stationery-2560.webp`"
        />
      </Padding>
    </Grid>
    <Padding>
      <div class="flex flex-col items-center">
        <Text class="mt-12">
          <h3 class="!text-primary">Umfassende Neugestaltung …</h3>
          <p>
            Gemeinsam mit unserem Partner Happy & Fresh haben wir die lokale Marktsituation
            analysiert und eine Markenstrategie entwickelt, aus der wir dann einzelne
            Design-Elemente wie Farb- und Schriftgestaltung, Bild- und Formensprache abgeleitet
            haben.
          </p>
          <p>
            Das
            <strong>Logo</strong>
            erinnert an die klassische Untersuchungsleuchte im Behandlungszimmer, lässt aber auch
            Assoziationen mit einer Goldmünze aufkommen: Für den „Biss auf Gold“ braucht es gesunde
            Zähne! Die Initialen der Praxis sind auf subtile Weise in das Logo eingearbeitet: Die
            Buchstaben O, C, H und A finden sich allesamt im Signet wieder und machen es durch ihre
            Formgebung zu einer sprichwörtlich „runden“ Sache. Ergänzt wird das von Grün- und
            Blautönen dominierte Corporate Design durch wellenförmige Elemente, die an den Schwung
            gesunder Zahnkronen denken lassen.
          </p>
          <h3 class="!text-primary">… des Außenauftritts</h3>
          <p>
            Das Corporate Design bildete die Grundlage für die Entwicklung zahlreicher weiterer
            Materialien, wie z. B. Briefpapier und Visitenkarten, Formulare für Überweisungen,
            Medien zur Ärztekommunikation und Patientenaufklärung (Terminkärtchen, Aufklärungsbögen
            etc.) sowie öffentlichkeitswirksame Werbemittel, wie etwa Beachflags für feierliche
            Anlässe. Das neue Erscheinungsbild hat wesentlichen Anteil an der regionalen Bewerbung
            und Neu-Etablierung von Christian Lohses Praxis.
          </p>
        </Text>
      </div>
    </Padding>
  </Project>
</template>
